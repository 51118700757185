exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-app-privacy-jsx": () => import("./../../../src/pages/app/privacy.jsx" /* webpackChunkName: "component---src-pages-app-privacy-jsx" */),
  "component---src-pages-extra-jsx": () => import("./../../../src/pages/extra.jsx" /* webpackChunkName: "component---src-pages-extra-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kanji-jlpt-n-1-jsx": () => import("./../../../src/pages/kanji/jlpt_n1.jsx" /* webpackChunkName: "component---src-pages-kanji-jlpt-n-1-jsx" */),
  "component---src-pages-kanji-jlpt-n-2-jsx": () => import("./../../../src/pages/kanji/jlpt_n2.jsx" /* webpackChunkName: "component---src-pages-kanji-jlpt-n-2-jsx" */),
  "component---src-pages-kanji-jlpt-n-3-jsx": () => import("./../../../src/pages/kanji/jlpt_n3.jsx" /* webpackChunkName: "component---src-pages-kanji-jlpt-n-3-jsx" */),
  "component---src-pages-kanji-jlpt-n-4-jsx": () => import("./../../../src/pages/kanji/jlpt_n4.jsx" /* webpackChunkName: "component---src-pages-kanji-jlpt-n-4-jsx" */),
  "component---src-pages-kanji-jlpt-n-5-jsx": () => import("./../../../src/pages/kanji/jlpt_n5.jsx" /* webpackChunkName: "component---src-pages-kanji-jlpt-n-5-jsx" */),
  "component---src-pages-kanji-jsx": () => import("./../../../src/pages/kanji.jsx" /* webpackChunkName: "component---src-pages-kanji-jsx" */),
  "component---src-pages-study-jsx": () => import("./../../../src/pages/study.jsx" /* webpackChunkName: "component---src-pages-study-jsx" */),
  "component---src-templates-words-jsx": () => import("./../../../src/templates/words.jsx" /* webpackChunkName: "component---src-templates-words-jsx" */)
}

